<template>
<div>
  <b-container fluid>
    <b-row class="text-white bg-kpmg-blue py-4 mb-4">
      <b-col>
        <h1 class="kstyle">Recently tagged news</h1>
      </b-col>
    </b-row>
    <b-row class="mb-4" v-if="rows">
        <b-col>
          <div v-for="(item, index) in rows" :key="'activity' + index">
            {{item.updateDate}}
            - <router-link :to="{ name: 'NewsItem', params: { id: item.newsId }}" >{{item.newsTitle}}</router-link>
            - <router-link :to="{ name: 'Topic', params: { id: item.topicId }}" >{{item.topicName}}</router-link>
          </div>
          </b-col>
    </b-row>
    <b-row v-show="loadingNews" class="text-center mb-4" align-h="center">
      <b-col cols="1">
        <div><i class="fa fa-spinner fa-pulse fa-lg fa-fw"></i></div>
      </b-col>
    </b-row>
  </b-container>
</div>
</template>

<script>
import moment from 'moment'

export default {
  computed: {
    user: {
      get () {
        return this.$store.state.user
      }
    }
  },
  created: function () {
    this.loadData()
  },
  data () {
    return {
      fields: [
        { key: 'updateDate', sortable: true },
        { key: 'newsTitle', sortable: true },
        { key: 'topicName', sortable: true }
      ],
      filter: '',
      gridLoaderColor: 'black',
      gridLoaderSize: '10px',
      loadingNews: false,
      rows: false
    }
  },
  methods: {
    loadData: async function () {
      this.$logger.debug('loadData')
      this.loadingNews = true
      try {
        let apiName = 'cosmos'
        let path = `/recentactivity/publication-to-topic`
        this.$logger.debug(path)
        let response = await this.$Amplify.API.get(apiName, path)
        this.$logger.debug('bySector response: ', response)
        let rows = []
        rows = response
        for (let i = 0, len = rows.length; i < len; i++) {
          rows[i].user = rows[i].username
          rows[i].updateDate = moment(rows[i].created_at).format('YYYY-MM-DD HH:mm')
          rows[i].newsId = rows[i].publication.id
          rows[i].newsTitle = rows[i].publication.name
          rows[i].newsBody = rows[i].publication.body
          rows[i].newsLink = rows[i].publication.link
          rows[i].topicName = rows[i].regtopic.name
          rows[i].topicId = rows[i].regtopic.id
        }
        this.rows = rows
        this.$logger.debug(rows)
        this.$localDb.setItem('analyticsData', this.rows)
        this.loadingNews = false
      } catch (e) {
        this.$logger.warn('bySecotr loading error' + e)
      }
    }
  }
}
</script>

<style>
</style>
